import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import { JwtModule } from '@auth0/angular-jwt';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Environment } from '@hofstede-insights/crm/shared/util/environment';
import { environment } from '../environments/environment';
import { ApplicationSelectorConstants } from '@hofstede-insights/shared/feature/application-selector';
import { applicationSelectorConstants } from '../environments/application-selector-constants';
import { gatewayUrlConstants } from '../environments/gateway-url-constants';
import { GatewayUrlConstants } from '@hofstede-insights/shared/util/gateway';

registerLocaleData(localeEnGB);
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        RouterModule.forRoot([
            {
                path: '',
                loadChildren: () => import('@hofstede-insights/crm/modules/feature-modules').then((module) => module.CrmModulesFeatureModulesModule),
            },
            {
                path: 'login',
                loadChildren: () => import('@hofstede-insights/crm/login/feature-login').then((module) => module.CrmLoginFeatureLoginModule),
            },
            {
                path: 'forgotpw',
                loadChildren: () => import('@hofstede-insights/crm/login/feature-forgotpw').then((module) => module.CrmLoginFeatureForgotpwModule),
            },
        ], { initialNavigation: 'enabledBlocking' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['feedbackdialog.com, localhost'],
                /*
                https://github.com/auth0/angular2-jwt
                ,
                whitelistedDomains: ['example.com'],
                blacklistedRoutes: ['example.com/examplebadroute/']
                */
            },
        })], providers: [
        {
            provide: Environment,
            useValue: environment,
        },
        {
            provide: ApplicationSelectorConstants,
            useValue: applicationSelectorConstants,
        },
        {
            provide: GatewayUrlConstants, useValue: gatewayUrlConstants
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
