import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@hofstede-insights/shared/util/authentication';
import { GatewayUrlService } from '@hofstede-insights/shared/util/gateway';
import { NotificationService } from '@hofstede-insights/shared/util/notification';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable( {
  providedIn: 'root',
})
export class HttpclientService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private router: Router,
    private gatewayUrlService: GatewayUrlService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  get(url: string): Observable<any> {
    return this.request(url, 'Get');
  }

  getBinary(url: string): Observable<any> {
    return this.request(url, 'Get', undefined, true);
  }

  delete(url: string): Observable<any> {
    return this.request(url, 'Delete');
  }

  post(url: string, data: Object): Observable<any> {
    return this.request(url, 'Post', JSON.stringify(data));
  }

  postBinary(url: string, data: Object): Observable<any> {
    return this.request(url, 'Post', JSON.stringify(data), true);
  }

  postFormData(url: string, data: Object): Observable<any> {
    return this.request(url, 'Post', data, false, true);
  }

  put(url: string, data: Object): Observable<any> {
    return this.request(url, 'Put', JSON.stringify(data));
  }

  private request(url: string, method: string, data?: Object, binary?: boolean, multipart?: boolean): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();

    if (this.authenticationService.getToken() !== '') {
      headers = headers.set('Authorization', 'Bearer ' + this.authenticationService.getToken());
    }

    const obser = undefined;
    if (method === 'Post') {
      // obser = 'response';
    }

    if (multipart) {
      // we want to leave content type header empty here.
      // headers = headers.set('Transfer-Encoding', 'chunked');

    } else {
      if (method === 'Post' || method === 'Put') {
        headers = headers.set('Content-Type', 'application/json');
      }
    }
    let body;
    if (data) {
      body = data;
    }

    const environment = localStorage.getItem('environment') || '';
    headers = headers.set('environment', environment);

    if (binary) {
      return this.http.request(method, this.gatewayUrlService.getGateway()
        + url, { headers: headers, body: body, observe: obser, responseType: 'blob' }).pipe(
          catchError((error) => {
            console.error(error);

            if (error.status === 200) { // this addresses bug with 200 with empty body, could be removed when bug fixed.
              const res = new HttpResponse({
                body: null,
                headers: error.headers,
                status: error.status,
                statusText: error.statusText,
                url: error.url
              });

              return of(res);
            }

            if (error.status === 401) {
              let refreshHeaders: HttpHeaders = new HttpHeaders();
              refreshHeaders = refreshHeaders.set('Authorization', 'Bearer ' + this.authenticationService.getRefreshToken());
              refreshHeaders = refreshHeaders.set('grant_type', 'refresh_token');
              refreshHeaders = refreshHeaders.set('environment', environment);

              return this.http.post(this.authenticationService.getTokenEndpoint(), '', { headers: refreshHeaders }).pipe(
                mergeMap((resp: any) => {
                  this.authenticationService.setToken(resp.token);
                  return this.request(url, method, data, binary, multipart);
                })).pipe(
                  catchError((error2) => {
                    console.error('e2');
                    if (error2.status === 500) {
                      return throwError(() => error2);
                    }
                    this.authenticationService.clearLoginData();
                    return from(this.router.navigate(['/login']));
                  }));
            }

            return throwError(() => error);
          }));
    } else {
      return this.http.request(method, this.gatewayUrlService.getGateway()
        + url, { headers: headers, body: body, observe: obser }).pipe(catchError((error) => {
          if (error.status === 200) { // this addresses bug with 200 with empty body, could be removed when bug fixed.
            const res = new HttpResponse({
              body: null,
              headers: error.headers,
              status: error.status,
              statusText: error.statusText,
              url: error.url
            });

            return of(res);
          }

          if (error.status === 401) {
            let refreshHeaders: HttpHeaders = new HttpHeaders();
            refreshHeaders = refreshHeaders.set('Authorization', 'Bearer ' + this.authenticationService.getRefreshToken());
            refreshHeaders = refreshHeaders.set('grant_type', 'refresh_token');
            refreshHeaders = refreshHeaders.set('environment', environment);
            return this.http.post(this.authenticationService.getTokenEndpoint(), '', { headers: refreshHeaders }).pipe(
              mergeMap((resp: any) => {
                this.authenticationService.setToken(resp.token);
                return this.request(url, method, data, binary, multipart);
              })).pipe(
                catchError((error2) => {
                  console.error('e2' + error2.status);
                  if (error2.status === 500 || error2.status === 503 || error2.status === 404) {
                    return throwError(() => error2);
                  }
                  this.authenticationService.clearLoginData();
                  this.router.navigate(['/login']);
                  this.notificationService.showError(this.translate.instant('Session expired. You have been logged off.'));
                  return of(undefined);
                }));
          }

          return throwError(() => error);
        }));
    }
  }
}
