import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { GatewayUrlService } from '@hofstede-insights/shared/util/gateway';

export interface TokenResponse {
    token: string;
    refreshToken: string;
    username: string;
    twoFactorAuthEnabled: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private tokenUrl = 'service/auth/token';
    private twoFactorAuthUrl = 'service/auth/2fa';

    private http!: HttpClient;

    constructor(
        private injector: Injector,
        private gatewayUrlService: GatewayUrlService,
        public jwtHelper: JwtHelperService
    ) {
        setTimeout(() => this.http = injector.get(HttpClient));
    }

    // store "codelogin" to local storage for later use
    // this is used when user login with code instead of username + pw
    private setCodeLogin(value: string) {
        localStorage.setItem('codelogin', value);
    }

    login(username: string, password: string, environment: string): Observable<TokenResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('username', username).set('password', password).set('grant_type', 'password').set('environment', environment);
        this.setCodeLogin('false');
        return this.http.post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers })
    }

    check2fa(username: string, key: string, environment: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('username', username).set('key', key).set('environment', environment);

        return this.http.post(this.gatewayUrlService.getGateway() + this.twoFactorAuthUrl, '', { headers });
    }

    sendLoginCode(key: string, environment: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('key', key).set('grant_type', 'sendcode').set('environment', environment);

        return this.http.post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers });
    }

    sendPassword(key: string, environment: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('key', key).set('grant_type', 'sendpassword').set('environment', environment);

        return this.http.post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers });
    }

    loginByCode(loginCode: string, password: string, environment: string): Observable<TokenResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('key', loginCode).set('password', password).set('grant_type', 'password').set('environment', environment);
        this.setCodeLogin('true');

        return this.http
            .post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers });
    }

    loginBySso(loginCode: string, environment: string): Observable<TokenResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('key', loginCode).set('grant_type', 'sso').set('environment', environment);
        return this.http
            .post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers });
    }

    hijack(username: string, key: string, password: string, environment: string): Observable<TokenResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            .set('username', username)
            .set('key', key)
            .set('password', password)
            .set('grant_type', 'hijack')
            .set('environment', environment);

        return this.http
            .post<TokenResponse>(this.gatewayUrlService.getGateway() + this.tokenUrl, '', { headers })
    }

    getToken(): string {
        return localStorage.getItem('token') || '';
    }

    isUserInRole(role: string): boolean {
        const token = this.getToken();
        if (token !== null && token !== '') {
            const payload = this.jwtHelper.decodeToken(token);
            if (payload.scopes.indexOf(role) >= 0) {
                return true;
            }
        }
        return false;
    }

    getUserId(): string {
        const token = this.getToken();
        if (token !== null && token !== '') {
            const payload = this.jwtHelper.decodeToken(token);
            return payload.userid;
        }
        return '';
    }

    getRefreshToken(): string {
        return localStorage.getItem('refreshToken') || '';
    }

    loggedIn() {
        return this.jwtHelper.isTokenExpired();
    }

    getTokenEndpoint(): string {
        return this.gatewayUrlService.getGateway() + this.tokenUrl;
    }

    setToken(token: string) {
        if (token !== '') {
            localStorage.setItem('token', token);
        }
    }

    setHijack(hijack: boolean) {
        localStorage.setItem('hijack', '' + hijack);
    }

    getHijack(): boolean {
        return localStorage.getItem('hijack') === 'true';
    }

    setRefreshToken(refreshToken: string) {
        if (refreshToken !== '') {
            localStorage.setItem('refreshToken', refreshToken);
        }
    }

    clearLoginData() {
        // localStorage.removeItem('isLoggedin');
        localStorage.removeItem('token');
        localStorage.removeItem('hijack');
        localStorage.removeItem('refreshToken');
        // localStorage.removeItem('loggedInUser');
        // localStorage.removeItem('environment');
    }

    /*     setLoggedInUser(username: string) {
            if (username !== '') {
                localStorage.setItem('loggedInUser', username);
            }
        }
     */

    // Helper methods for different scenarios
    checkCompanyAdmin(): boolean {
        return (this.isUserInRole('ROLE_SYSTEM_ADMIN')
            || this.isUserInRole('ROLE_COMPANY_USER')
            || this.isUserInRole('ROLE_USERS_ADMIN'));
    }

}
