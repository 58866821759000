import { Injectable } from '@angular/core';
import { HttpclientService } from '@hofstede-insights/shared/util/httpclient';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { AnalyticsEntry } from './analyticsentry';
import { AnalyticsReport } from './analyticsreport';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private url = 'logging/analytics';

    deviceInfo!: DeviceInfo;

    constructor(
        private http: HttpclientService,
        private deviceService: DeviceDetectorService
    ) {
        this.getDeviceInfo();
    }

    private getDeviceInfo() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }

    private create(entry: AnalyticsEntry): Observable<void> {
        return this.http.post(`${this.url}/`, entry);
    }

    logNavigation(url: string) {
        const entry = new AnalyticsEntry();
        entry.url = url;
        entry.actionType = 'navigation';
        entry.userAgent = this.deviceInfo.userAgent;
        this.create(entry).subscribe(() => { });
    }

    logAnalytics(url?: string, at?: string, parameters?: { key: string; value: string; }[]) {
        const entry = new AnalyticsEntry();
        entry.url = url;
        entry.actionType = at;
        entry.userAgent = this.deviceInfo.userAgent;
        entry.keyValues = parameters;
        this.create(entry).subscribe(() => { });
    }

    logSelection2(parameters: string[]) {
        const entry = new AnalyticsEntry();
        entry.keyValues = [];
        if (parameters.length >= 2) {
            entry.keyValues.push({ key: parameters[0], value: parameters[1] })
        }
        if (parameters.length >= 4) {
            entry.keyValues.push({ key: parameters[2], value: parameters[3] })
        }
        entry.actionType = 'selection';
        entry.userAgent = this.deviceInfo.userAgent;
        this.create(entry).subscribe(() => { });
    }

    logSelection(key: string, value: string, key2?: string, value2?: string) {
        const entry = new AnalyticsEntry();
        entry.keyValues = [];
        entry.keyValues.push({ key: key, value: value })
        if (key2 && value2) {
            entry.keyValues.push({ key: key2, value: value2 })
        }
        entry.actionType = 'selection';
        entry.userAgent = this.deviceInfo.userAgent;
        this.create(entry).subscribe(() => { });
    }

    getEntriesForUser(userId: string): Observable<AnalyticsEntry[]> {
        return this.http.get(`${this.url}/byuser/${userId}`)
    }

    getEntriesForUrl2(url: string, from?: number, to?: number): Observable<AnalyticsEntry[]> {
        let queryParams = `?u=${url}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/url${queryParams}`);
    }

    getEntries(): Observable<AnalyticsEntry[]> {
        return this.http.get(`${this.url}`);
    }

    getEntriesToday(): Observable<AnalyticsEntry[]> {
        return this.http.get(`${this.url}/today`);
    }

    getEntriesWeek(): Observable<AnalyticsEntry[]> {
        return this.http.get(`${this.url}/week`);
    }

    search(search: AnalyticsEntry): Observable<AnalyticsEntry[]> {
        return this.http.post(`${this.url}/search`, search);
    }

    getEntriesForUrl(url: string): Observable<AnalyticsEntry[]> {
        const search = new AnalyticsEntry();
        search.url = url;
        return this.http.post(`${this.url}/search`, search);
    }

    getEntriesForUsername(username: string): Observable<AnalyticsEntry[]> {
        const search = new AnalyticsEntry();
        search.username = username;
        return this.http.post(`${this.url}/search`, search);
    }

    getEntriesForIP(ip: string): Observable<AnalyticsEntry[]> {
        const search = new AnalyticsEntry();
        search.ipAddress = ip;
        return this.http.post(`${this.url}/search`, search);
    }

    getEntriesForActionType(at: string): Observable<AnalyticsEntry[]> {
        const search = new AnalyticsEntry();
        search.actionType = at;
        return this.http.post(`${this.url}/search`, search);
    }

    getTopUrls(limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?l=${limit}` : '';
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/url${queryParams}`);
    }

    getTopUsers(limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?l=${limit}` : ``;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/user${queryParams}`);
    }

    getTopUrlsForUser(username: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?u=${username}&l=${limit}` : `?u=${username}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/u${queryParams}`);
    }

    getTopUsersForUrl(url: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?p=${url}&l=${limit}` : `?p=${url}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/p${queryParams}`);
    }

    getTopIpAddressesForUrl(url: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?p=${url}&l=${limit}` : `?p=${url}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/ip${queryParams}`);
    }

    getTopUseragentsForUrl(url: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?p=${url}&l=${limit}` : `?p=${url}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/ua${queryParams}`);
    }

    getTopIpAddressesForUsername(username: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?u=${username}&l=${limit}` : `?u=${username}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/ip${queryParams}`);
    }

    getTopUseragentsForUsername(username: string, limit?: number, from?: number, to?: number): Observable<AnalyticsReport[]> {
        let queryParams = limit ? `?u=${username}&l=${limit}` : `?u=${username}`;
        queryParams += from ? `&f=${from}` : '';
        queryParams += to ? `&t=${to}` : '';
        return this.http.get(`${this.url}/report/ua${queryParams}`);
    }

}
