
export class AnalyticsEntry {
    id?: string;
    userId?: string;
    username?: string;
    createTime?: Date;
    url?: string;
    actionType?: string;
    keyValues?: { key: string; value: string; }[];
    userAgent?: string;
    ipAddress?: string;
}
