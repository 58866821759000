import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CurrentLocaleService  {
    locale = 'en-GB';

    constructor() {
        const currentLocale = localStorage.getItem('currentLocale');
        if (currentLocale) {
            this.locale = currentLocale;
        }
    }

    setLocale(locale: string) {
        this.locale = locale;
        localStorage.setItem('currentLocale', locale);
    }

    getLocale(): string {
        return this.locale;
    }
}
