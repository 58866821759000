import { Injectable } from '@angular/core';
import {GatewayUrlConstants} from './gateway-url-constant';

@Injectable({
    providedIn: 'root',
  })
export class GatewayUrlService  {

    // Test
    // private gatewayUrl = 'https://gw.feedbackdialog.com/';

    // Production
    // (private gatewayUrl = 'https://gw2.feedbackdialog.com/';)
    // private gatewayUrl = 'https://gw3.feedbackdialog.com/';

    // Raimo
    // private gatewayUrl = 'https://127.0.0.1:9000/'; // non IE 
    // private gatewayUrl = 'http://127.0.0.1:8990/'; // new cloud gateway
    // to test with ie: ng serve --configuration es5
    // private gatewayUrl = 'https://localhost:9000/'; //ie

    // Juha
    // private gatewayUrl = 'https://gwdev.feedbackdialog.com/';
    // private gatewayUrl = 'http://127.0.0.1:8990/';
    
    constructor(private gatewayUrlConstants: GatewayUrlConstants) {
    }

    getGateway(): string {
        return this.gatewayUrlConstants.gatewayUrl;
    }
}