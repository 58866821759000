import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@hofstede-insights/shared/domain/analytics';
import { VersionCheckService } from '@hofstede-insights/shared/util/version-check';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthenticationService } from '@hofstede-insights/shared/util/authentication';
import { CurrentLocaleService } from '@hofstede-insights/shared/util/currentlocale';

// #3122
// 8.5.2023 JNi
// uncomment following line if you are building test/prod or you want to track usage on your local
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'hofstede-insights-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'crm';

  constructor(
    private translate: TranslateService,
    private versionCheckService: VersionCheckService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private localeService: CurrentLocaleService,
  ) { 
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // #3122
      // 8.5.2023 JNi
      // START : Code to Track Page View using gtag.js
      gtag('event', 'page_view', {
        env: localStorage.getItem('environment')
      })
      // END : Code to Track Page View  using gtag.js

      if (this.authenticationService.getToken() !== '') {
        analyticsService.logNavigation(event.urlAfterRedirects);
      }
    })    
  }

  ngOnInit() {
    console.log('booting up', environment)
    if (environment.production) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL, 100000);
    }

    this.localeService.setLocale('en-GB');

    this.translate.addLangs(['en', 'fi', 'de']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    // const browserLang = this.translate.getBrowserLang();
    // const storedLanguage = localStorage.getItem('language');
    // if (storedLanguage) {
    //   this.translate.use(storedLanguage);
    // } else {
    //   this.translate.use(browserLang.match(/en|fi|de/) ? browserLang : 'en');
    // }
  }
}